import React from 'react';
import { Button } from './PrimaryButtonstyled';

export const PrimaryButton = ({btnText,className, handleOnclick,lg,md, fullWidth, disabled,bg, type, margin, hideOnMobile, hideOnDesktop})=> {
    const handleclick = () =>{
        if (handleOnclick && !disabled){
            handleOnclick();
        }
    }

    return(

        <Button
            margin={margin}
            bg={bg}
            type={type}
            disabled={disabled}
            onClick={handleclick} 
            lg={lg}
            md ={md}
            fullWidth = {fullWidth}
            className={className}
            hideOnMobile ={hideOnMobile}
            hideOnDesktop ={hideOnDesktop}
            >
            {btnText}
        </Button>
    );



};
