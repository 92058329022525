import styled from 'styled-components';
import {theme} from '../../../theme';

export const InternationalPhoneInputWrapper = styled.div`
    position: relative;
    padding-bottom: 1px;
    margin-bottom: 5px;
    width: 100%;
`

export const InternationalPhoneInputStyled =  styled.div`
    margin-bottom: 30px;
    padding-bottom: 1px;
    width:${props=>props.fullWidth ? '100%' : '350px'};
    ${theme.mediaQueriesMaxWidth.sm}{
        width:${props=>props.fullWidth ? '100%' : '100%'};
    }
    border: 1px solid #D5D5E5;
    box-sizing: border-box;
    border-radius: 4px;
    padding-left: 25px;
`;

export const InputWrapper =  styled.div`
    position:relative;    
    input {
        width:100%;
        height:45px;
        border: none;
        padding: 10px 10px;
    }
    span {
        margin-bottom:25px;
        position:absolute;
        z-index:1;
        margin-top:10px;
        padding-right:15px;
        svg{
            height:18px;
            width:18px;
        }
    }
    width: 100%;

`;