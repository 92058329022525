import styled from "styled-components";

export const PlayerStyles = {
  margin: "auto",
  maxWidth: "656px",
};

export const PlayIcon = (
  <svg
    width="66"
    height="65"
    viewBox="0 0 66 65"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle
      cx="33.0001"
      cy="32.4694"
      r="29.5782"
      stroke="white"
      stroke-width="5"
    />
    <path
      d="M43.6929 32.4694L27.6538 41.7295L27.6538 23.2092L43.6929 32.4694Z"
      fill="white"
    />
  </svg>
);

export const ThankYouPageWrapper = styled.div`
  padding-top: 80px;

  .small-cloud {
    position: absolute;
    top: 100px;
    left: 26px;
  }

  .big-cloud {
    position: absolute;
    top: 100px;
    right: 150px;
  }

  .half-cloud {
    position: absolute;
    top: 310px;
    right: 0;
  }

  .dollar-icon1 {
    position: absolute;
    top: 380px;
    right: 60px;
  }
  .dollar-icon2 {
    position: absolute;
    top: 470px;
    left: 70px;
  }

  ${(props) => props.theme.mediaQueriesMaxWidth.sm} {
    .small-cloud {
      right: 0px;
      left: auto;
      top: 100px;
    }

    .half-cloud {
      top: 340px;
    }

    .big-cloud {
      left: 0px;
    }

    .dollar-icon1 {
      top: 400px;
    }

    .dollar-icon2 {
      position: absolute;
      top: 440px;
      left: 30px;
    }
  }
`;

export const ThankYouSection = styled.section`
  position: relative;
  text-align: center;
  padding: 120px 0px 150px 0px;
  h1 {
    margin-bottom: 24px;
    font-family: ${(props) => props.theme.fonts.Replica_bold};
    color: ${(props) => props.theme.colors.red};
  }
  p {
    max-width: 710px;
    margin-left: auto;
    margin-right: auto;
    font-family: ${(props) => props.theme.fonts.Replica_Regular};
    color: ${(props) => props.theme.colors.brand_black};
    font-size: 20px;
    margin-bottom: 24px;
  }

  ${(props) => props.theme.mediaQueriesMaxWidth.sm} {
    padding: 60px 32px 140px 32px;
    h1 {
      margin: auto;
      font-size: 30px;
    }
    p {
      font-size: ${(props) => props.theme.fontSizes.md};
      margin-bottom: 14px;
    }
  }
`;

export const VideoSection = styled.section`
  padding: 80px;
  background-color: ${(props) => props.theme.colors.lightgrey};
  text-align: center;
  h3 {
    font-family: ${(props) => props.theme.fonts.Replica_bold};
    color: ${(props) => props.theme.colors.red};
    margin: 40px;
  }

  img {
    max-width: 656px;
    width: 100%;
  }

  ${(props) => props.theme.mediaQueriesMaxWidth.sm} {
    padding: 32px;
    h3 {
      margin: 16px;
      font-size: 20px;
    }
  }
`;

export const PlayerWrapper = styled.div`
  height: 384px;
  svg:hover {
    circle {
      stroke: red;
    }
    path {
      fill: red;
    }
  }
  ${(props) => props.theme.mediaQueriesMaxWidth.sm} {
    height: 175px;
  }
`;

export const FAQSection = styled.section`
  padding: 80px;
  h1 {
    text-align: center;
    font-family: ${(props) => props.theme.fonts.Replica_bold};
    color: ${(props) => props.theme.colors.red};
  }
  .faq-container {
    padding: 0px 100px 0px 100px;
  }
  ${(props) => props.theme.mediaQueriesMaxWidth.sm} {
    padding: 32px;

    h1 {
      font-size: 30px;
    }
    .faq-container {
      padding: 0px;
    }
  }
`;
