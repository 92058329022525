import './utils/helpers';
import React, { useState } from 'react';
import { Router } from '@reach/router';
import { NavbarMenu } from './common/NavbarMenu/NavbarMenu';
import './App.css';
import { Footer } from './common/Footer/Footer';
import { LeaseCalculator } from './common/LeaseCalculator';
import { Quote } from './common/Quote';
import GlobalStyles from './styles/base';
import HttpsRedirect from 'react-https-redirect';
import { theme } from './theme';
import './styles/App.scss';
import { ThemeProvider } from 'styled-components';
import 'react-toastify/dist/ReactToastify.min.css';
import { ToastContainer } from 'react-toastify';
import { AppWrapper } from './styles/AppStyled';
import { ThankYou } from './common/ThankYou';

function App() {
    const [menuCollapse, setMenuCollapse] = useState(true);
    return (
        <div className="App">
            <ThemeProvider theme={theme}>
                <GlobalStyles />
                <NavbarMenu
                    menuCollapse={menuCollapse}
                    setMenuCollapse={setMenuCollapse}
                />
                <AppWrapper menuCollapse={menuCollapse}>
                    <HttpsRedirect>
                        <Router>
                            <LeaseCalculator path="/" />
                            <LeaseCalculator path="/ev" />
                            <LeaseCalculator path="/nsw-health" />
                            <Quote path="quote" />
                            <ThankYou path="thankyou" />
                        </Router>
                    </HttpsRedirect>
                    <Footer />
                </AppWrapper>

                <ToastContainer />
            </ThemeProvider>
        </div>
    );
}

export default App;
