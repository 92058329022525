import styled from 'styled-components';
import {theme} from '../../../theme';

export const DropdownWrappper =  styled.div`
     position:relative;
     padding-bottom:26px;
     margin-bottom: 5px;
     width:${props=>props.fullWidth ? '100%' : '350px'};
     ${theme.mediaQueriesMaxWidth.sm}{
          width:100%;
     }
`;

export const DropdownWrap =  styled.div`
     margin-top: 15px;
     background-color:${theme.colors.lightgrey};
     padding :23px 30px;
     p{
          font-size:23px;
          color:${theme.colors.easiblue};
     }
     ${theme.mediaQueriesMaxWidth.md}{
          p{
               font-size: 21px;
          }
      }
      ${theme.mediaQueriesMaxWidth.sm}{
          padding-left:15px;
          padding-right:15px;
          p{
              font-size:19px;
              font-weight:normal;
          }
      }
`
