import styled from "styled-components";
export const DownArrow = (
  <svg
    width="14"
    height="9"
    viewBox="0 0 14 9"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M7 7.49575L7.53028 8.02612C7.2374 8.31895 6.7626 8.31895 6.46972 8.02612L7 7.49575ZM0.344717 1.9022C0.0517976 1.60934 0.0517557 1.13446 0.344623 0.841543C0.637491 0.548624 1.11236 0.548582 1.40528 0.84145L0.344717 1.9022ZM12.5947 0.84145C12.8876 0.548582 13.3625 0.548624 13.6554 0.841543C13.9482 1.13446 13.9482 1.60934 13.6553 1.9022L12.5947 0.84145ZM6.46972 8.02612L0.344717 1.9022L1.40528 0.84145L7.53028 6.96537L6.46972 8.02612ZM13.6553 1.9022L7.53028 8.02612L6.46972 6.96537L12.5947 0.84145L13.6553 1.9022Z"
      fill="#DC1C23"
    ></path>
  </svg>
);

export const AccordionItemStyled = styled.div`
  .acc-container {
    border-bottom: 2px solid ${(props) => props.theme.colors.red};
    font-family: "Replica", sans-serif;
    font-size: 17px;
    -webkit-font-smoothing: antialiased;
    text-align: left;
    cursor: pointer;
  }
  .acc-head {
    padding-left: 15px;
    color: ${(props) => props.theme.colors.darkGray};
    transition: 200ms ease-out;
    font-size: 17px;
    font-weight: 700;
  }
  .acc-body {
    ${(props) =>
      props.open
        ? "height:auto; opacity:1;margin-bottom: 30px;"
        : "height:0px;opacity:0;margin-bottom: 0px;"};
    overflow: hidden;
    padding-left: 45px;
    color: ${(props) => props.theme.colors.darkGray};
    font-size: 17px;
    font-weight: 500;
    transition: height, opacity 0.5s ease-in-out;
  }
  .acc-arrow {
    color: ${(props) => props.theme.colors.red};
    font-size: 20px;
    svg {
      transform: ${(props) => (props.open ? "rotate(0deg)" : "rotate(-90deg)")};
      transition: all 0.5s ease-in-out;
    }
  }
  .acc-head-container {
    padding: 20px 30px 20px 0px;
    display: flex;
    justify-content: start;
    align-items: center;
    transition: all 0.5s ease-in-out;
  }
  .acc-head-container:hover {
    .acc-head {
      margin-left: 3px;
      color: ${(props) => props.theme.colors.red};
      transition: all 0.5s ease-in-out;
    }
  }
  .acc-body-link {
    text-decoration: none;
    color: ${(props) => props.theme.colors.red};
  }
  .acc-body-link:hover {
    color: ${(props) => props.theme.colors.easiblue};
    transition: all 0.5s ease-in-out;
  }
`;
