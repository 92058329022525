import {theme} from '../../theme';
import styled from 'styled-components';
export const PackageCardWrappper =  styled.div`

    .card-text:last-child
    {
        font-size:10px;
        color:${theme.colors.easiblue};
        font-family:${theme.fonts.Replica_Regular};
    }
     .card-title {
        font-size:8px;
        color:${theme.colors.easiblue};
        font-family:${theme.fonts.Replica_Regular};
        margin:0;
     }
     .card{
        background: transparent;
        width:70px;
        height:auto;
        align-items: center;
        border:none;
        img{
            height: 35px;
            width: 35px;
        }
     }
     .card-body{
         text-align:center;
         padding:12px;
        
     }


   
`;

