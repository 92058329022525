import React from 'react';
import {
    PackageCardWrappper
} from './PackageCardStyled';
import {Card} from 'react-bootstrap';

export const PackageCard = ({label,price,className,imgUrl}) => {
    return (
      <PackageCardWrappper className={className}>
        <Card>
            <img variant="top" alt="card_img" src={imgUrl} />
            <Card.Body>

              <Card.Text>
                {label}
              </Card.Text>
            </Card.Body>
        </Card>

      </PackageCardWrappper>
    
    );
};
