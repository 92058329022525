import { navigate } from "@reach/router";
import React, { useState } from "react";
import { useEffect } from "react";
import { Image } from "react-bootstrap";
import ReactPlayer from "react-player/lazy";
import { PrimaryButton } from "../PrimaryButton";
import Accordion from "../Accordion/Accordion";
import { faqs } from "./faqs.js";
import {
  FAQSection,
  PlayerStyles,
  PlayerWrapper,
  PlayIcon,
  ThankYouPageWrapper,
  ThankYouSection,
  VideoSection,
} from "./styled";

export const ThankYou = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [openedIndex, setOpenedIndex] = useState(false);

  const renderFAQS = () => {
    return faqs.map((faq, i) => (
      <Accordion
        key={i}
        head={faq.title}
        body={faq.body}
        opened={openedIndex === i}
        handleOnClick={() => {
          if (openedIndex === i) {
            setOpenedIndex(false);
          } else {
            setOpenedIndex(i);
          }
        }}
      />
    ));
  };

  return (
    <ThankYouPageWrapper>
      <Image className="small-cloud" alt="icons" src="/Images/Vector3.png" />
      <Image className="big-cloud" alt="icon" src="/Images/Vector.png" />
      <Image className="half-cloud" alt="icons" src="/Images/Vector2.png" />
      <Image
        className="dollar-icon1"
        alt="icon"
        width="54"
        height="54"
        src="/Images/Group.png"
      />
      <Image
        className="dollar-icon2"
        alt="icon"
        width="54"
        height="54"
        src="/Images/Group.png"
      />

      <ThankYouSection className="container">
        <h1>Thank You!</h1>
        <p>
          Thank you for enquiring about a novated car lease. 
          We will be in touch with you very soon with your request.
        </p>
        <PrimaryButton
          type="button"
          btnText="Return to Home Page"
          handleOnclick={() => navigate("/")}
        />
      </ThankYouSection>
      <VideoSection>
        <PlayerWrapper>
          <ReactPlayer
            url={
              "https://vimeo.com/362459242?embedded=true&source=video_title&owner=99070819"
            }
            light={true}
            height="100%"
            width="100%"
            style={PlayerStyles}
            playIcon={PlayIcon}
            playing={true}
          />
        </PlayerWrapper>
        <h3>An Easi Novated Lease Can Save You Thousands.</h3>
        <PrimaryButton
          type="button"
          btnText="Watch Video"
          handleOnclick={() =>
            window.open(
              "https://vimeo.com/362459242?embedded=true&source=video_title&owner=99070819"
            )
          }
        />
      </VideoSection>
      <FAQSection className="container">
        <h1>Frequently Asked Questions</h1>
        <div className="faq-container">{renderFAQS()}</div>
      </FAQSection>
    </ThankYouPageWrapper>
  );
};
