import { AccordionItemStyled, DownArrow } from "./styled";
import React from "react";

const Accordion = ({ head, body, opened, handleOnClick }) => {
  return (
    <AccordionItemStyled open={opened}>
      <div className="acc-container">
        <div className="acc-head-container" onClick={handleOnClick}>
          <span className="acc-arrow">{DownArrow}</span>
          <span className="acc-head">{head}</span>
        </div>

        <div className="acc-body">{body}</div>
      </div>
    </AccordionItemStyled>
  );
};

export default Accordion;
