export const faqs = [
  {
    title: 'What is “all inclusive car finance”?',
    body: (
      <p>
        Salary sacrifice/ Salary Packaging / Novated leasing a car is where you bundle together 
        the finance and running costs of your car into one easy package which comes out of your 
        salary each pay. The benefit to doing this is you save income tax on the running costs of 
        your car which you will have to pay anyway. You also don’t 
        pay GST on the purchase price of the car saving you thousands.
      </p>
    ),
  },
  {
    title: "Is there a credit check involved?",
    body: (
      <p>
        Yes, finance is subject to approval from our panel of financiers. 
      </p>
    ),
  },
  {
    title: "Do I need to be employed?",
    body: (
      <p>
        Yes, you need to be an employee paying income tax to unlock the benefits of novated leasing.
      </p>
    ),
  },
  {
    title: "What are the terms available?",
    body: (
      <p>
        You can lease the car minimum 12 months, and maximum 60 months.
      </p>
    ),
  },
  {
    title: "Can I have more than one novated lease?",
    body: (
      <p>
        Yes, you can take out as many as you can afford and save tax on each car in your household.
      </p>
    ),
  },
  {
    title: "What happens if I change employer during the term?",
    body: (
      <div class="accordion__panel-inner" itemprop="text">
        <p>You have a few options,</p>
        <p>
          •{" "}
          <span>Transfer your lease to your new employer (subject to employers’ approval).</span>
        </p>
        <p>
          •{" "}
          <span>Continue to pay the lease and running cost yourself after tax.</span>
        </p>
        <p>
          •{" "}
          <span>Payout the vehicle and own it outright.</span>
        </p>
      </div>
    ),
  },
  {
    title: "Can I arrange my own comprehensive car insurance policy and roadside assistance?",
    body: (
      <p>
        Yes, you will need to arrange this with your chosen provider and cover the initial premium up front, 
        however you will be reimbursed once we have collected enough pre-tax funds. 
      </p>
    ),
  },
  {
    title: "What happens if I travel more/less than originally estimated?",
    body: (
      <p>
        The running costs budgeted for in your lease are flexible, meaning 
        you can increase/decrease how much you contribute for your running costs as life changes.
      </p>
    ),
  },
  {
    title: "Can I lease a used vehicle?",
    body: (
      <p>
        Yes, it’s up to you to locate the vehicle and negotiate the price. 
        The car can come from a dealership, wholesaler, auction or even private sale. 
        Good to have a pre-approval before you start shopping.
      </p>
    ),
  },
  {
    title: "Can I lease my own vehicle?",
    body: (
      <p>Yes, this is possible – speak to a consultant to guide you. </p>
    ),
  },
  {
    title: "Does the vehicle have to be under a certain age or kilometres?",
    body: (
      <p>There is no kilometre restriction, but typically the vehicle must be under 8 years old by the end of the lease. 
        This is judged on a case by case basis so check with your leasing specialist.
      </p>
    ),
  },
  {
    title: "Is it ok if someone else drives my car?",
    body: (
      <p>
        Yes, anyone can drive your vehicle. If they are a regular driver it’s a good idea 
        to consider having them added to your insurance policy to avoid a higher excess.
      </p>
    ),
  },
  {
    title: "Can I make modifications to the car?",
    body: (
      <p>
        Yes, you can, you are free to treat it as your own vehicle. Any major modifications 
        should be reported to the insurance company though.
      </p>
    ),
  },
  {
    title: "What happens if I don’t spend all my allocation for fuel, servicing, tyres and registration etc?",
    body: (
      <p>
        Any surplus in your maintenance account is returned to you at the end of the lease. 
      </p>
    ),
  },
  {
    title: "What happens at the end of a novated lease?",
    body: (
      <div class="accordion__panel-inner" itemprop="text">
        <p>You have a few options depending on the age of the vehicle:</p>
        <p>
            •{" "}
            <span>Salary Package a new vehicle</span>
        </p>
        <p>
            •{" "}
            <span>Salary Package your current vehicle again* </span>
        </p>
        <p>
            •{" "}
            <span>Payout the residual/balloon and end the tax savings – own outright.</span>
        </p>
      </div>
    ),
  },
];
