import React from 'react';
import {
  NavbarWrapper
} from './NavbarMenuStyled';
import {
  Navbar,
  Nav,
 
} from 'react-bootstrap';
import {
  ProSidebar,
  Menu,
  MenuItem,
  SidebarContent,
} from "react-pro-sidebar";
import "react-pro-sidebar/dist/css/styles.css";

export const NavbarMenu = ({menuCollapse,setMenuCollapse}) => {
        const menuIconClick = () => {
          menuCollapse ? setMenuCollapse(false) : setMenuCollapse(true);
        };
        
    return (
      <div>
      <NavbarWrapper>
        <Navbar expand="*">
            <Navbar.Brand href="https://www.easifleet.com.au/" className="logo"/>
              <div className="ml-auto d-inline-flex">
                <Nav.Link href="tel:1300%20266%20828">1300 266 828</Nav.Link>
                <Nav.Link href="#home">|</Nav.Link>
                <Nav.Link href="https://www.easifleet.com.au/contact-us/">Request a callback</Nav.Link>
                <Nav.Link href="http://members.easifleet.com.au/">Login</Nav.Link>
              </div>
              <div><img className="phone" alt="phone" src="/Images/phone.png"/></div>
              <div className="closemenu" onClick={menuIconClick}>
                            {/* changing menu collapse icon on click */}
                          {menuCollapse ? (
                             <button className="navbar-toggler nav-toggle-btn" type="button" aria-expanded="false"
                             aria-label="Toggle navigation">
                           
                             <div className="animated-icon"><span></span><span></span><span></span><span></span></div>
                           </button>
                             
                          ) : ( 
                            <button className="navbar-toggler nav-toggle-btn" type="button" aria-expanded="false"
                            aria-label="Toggle navigation">
                          
                            <div className="animated-icon"><span className="top-bar"></span><span className="middle-bar"></span><span className="middle-bar"></span><span className="bottom-bar"></span></div>
                          </button>
                          )}
              </div>
            <Navbar.Collapse id="basic-navbar-nav">
              <Nav className="ml-auto">
                <Nav.Link href="#home">1300 266 828</Nav.Link>
                <Nav.Link href="#link">Request a callback</Nav.Link>
                <Nav.Link href="#link">Login</Nav.Link>
              </Nav>
            </Navbar.Collapse>
      </Navbar>
              <div id="header" >
                    <ProSidebar collapsed={menuCollapse}>
                      <SidebarContent>
                        <Menu iconShape="square">
                          <MenuItem>
                           <a href="https://www.easifleet.com.au/"> <h2   className="Sidebar-Heading">Home</h2></a>
                          </MenuItem>
                          <MenuItem ><a href="https://www.easifleet.com.au/thats-so-easi/">All Inclusive Car Finance</a></MenuItem>
                          <MenuItem ><a href="https://www.easifleet.com.au/novated-lease-calculator/">Novated Lease Calculator</a></MenuItem>
                          <MenuItem ><a href="https://www.easifleet.com.au/novated-lease/">Novated Lease</a></MenuItem>
                          <MenuItem ><a href="https://www.easifleet.com.au/electric-vehicles/">Electric Vehicle Novated Leasing</a></MenuItem>
                          <MenuItem ><a href="https://www.easifleet.com.au/business-car-leasing/">Business Car Leasing</a></MenuItem>
                          <MenuItem ><a href="https://www.easifleet.com.au/easibuy/">EasiBuy</a></MenuItem>
                          <MenuItem ><a href="https://www.easifleet.com.au/salary-packaging/">Salary Packaging</a></MenuItem>
                          <MenuItem ><a href="https://www.easifleet.com.au/fleet-management/">Fleet Management</a></MenuItem>
                          <hr/>
                          
                          <MenuItem ><a href="https://www.easifleet.com.au/cars/"><h2 className="Sidebar-Heading">CAR DEALS</h2></a></MenuItem>
                          <hr/>
                          <MenuItem ><a href="https://www.easifleet.com.au/resources-and-forms/"><h2 className="Sidebar-Heading">RESOURCES</h2></a></MenuItem>
                          <hr/>
                          <MenuItem ><a href="https://www.easifleet.com.au/learn/">Learn</a></MenuItem>
                          <MenuItem ><a href="https://www.easifleet.com.au/resources-and-forms/forms/">Forms</a></MenuItem>
                          <MenuItem ><a href="https://www.easifleet.com.au/resources-and-forms/brochures-guides-3-2/">Brochures & Guides</a></MenuItem>
                          <hr/>
                          <MenuItem ><a href="https://www.easifleet.com.au/blog/"><h2 className="Sidebar-Heading">BLOG</h2></a></MenuItem>
                          <MenuItem ><a href="https://www.easifleet.com.au/blog/videos/">VIDEOS</a></MenuItem>
                          <hr/>
                          <MenuItem ><a href="https://www.easifleet.com.au/about-us/"><h2 className="Sidebar-Heading">ABOUT US</h2></a></MenuItem>
                          <hr/>
                          <MenuItem ><a href="https://www.easifleet.com.au/contact-us/"><h2 className="Sidebar-Heading">Contact Us</h2></a></MenuItem>
                          <hr/>
                        </Menu>
                      </SidebarContent>
                    </ProSidebar>
             </div>
    </NavbarWrapper>
    </div>
    
    );
};
