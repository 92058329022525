import React from 'react';
import { connect } from 'react-redux';
import { MeterWrappper, SubCosts, Cost } from './MeterStyled';
export const MeterContainer = ({
    slug,
    annual,
    fortnight,
    isLoading,
    isCalculating,
    heading1,
    text1,
    heading2,
    text2,
    runningCost,
    carCost,
    shareOfSavings,
    perFortnight,
}) => {
    var digits = annual
        .toString()
        .split('')
        .map((num) => (!isNaN(parseInt(num)) ? parseInt(num) : num));
    var digits2 = fortnight
        .toString()
        .split('')
        .map((num) => (!isNaN(parseInt(num)) ? parseInt(num) : num));
    if (digits.length < 5) {
        let padZeros = 5 - digits.length;
        digits = Array(padZeros).fill(0).concat(digits);
    }
    if (digits2.length < 4) {
        let padZeros = 4 - digits2.length;
        digits2 = Array(padZeros).fill(0).concat(digits2);
    }

    const renderAnnual = () => {
        return digits.map((number, i) => {
            return (
                <span id={`annual${i}`} key={i}>
                    {isCalculating ? (
                        <span
                            className={
                                i % 2 === 0
                                    ? 'numAnimate'
                                    : i % 3 === 0
                                    ? 'revNumAnimate'
                                    : 'lastNumAnimate'
                            }
                        ></span>
                    ) : (
                        number
                    )}
                </span>
            );
        });
    };
    const renderFortnight = () => {
        return digits2.map((number, i) => {
            return (
                <span id={`fortnight${i}`} key={i}>
                    {isCalculating ? (
                        <span
                            className={
                                i % 2 === 0
                                    ? 'numAnimate'
                                    : i % 3 === 0
                                    ? 'revNumAnimate'
                                    : 'lastNumAnimate'
                            }
                        ></span>
                    ) : (
                        number
                    )}
                </span>
            );
        });
    };
    return (
        <MeterWrappper>
            <p className="heading text">{heading1}</p>
            <div className="amount">
                <h4>$</h4>
                {renderAnnual()}
            </div>
            <p className="text">{text1}</p>
            <hr />
            <p className="heading text">{heading2}</p>
            <div className="amount">
                <h4>$</h4>
                {renderFortnight()}
            </div>
            <p className="text">{text2}</p>
            <SubCosts>
                <Cost>
                    <p>Fortnightly running costs</p>
                    <h3>
                        $
                        <span id="r_cost">
                            {isCalculating ? (
                                <>
                                    <span className="revNumAnimate"></span>
                                    <span className="numAnimate"></span>
                                    <span className="lastNumAnimate"></span>
                                </>
                            ) : (
                                runningCost || '000'
                            )}
                        </span>
                    </h3>
                </Cost>
                <Cost>
                    <p>Fortnightly cost of car</p>
                    <h3>
                        $
                        <span id="c_cost">
                            {isCalculating ? (
                                <>
                                    <span className="revNumAnimate"></span>
                                    <span className="numAnimate"></span>
                                    <span className="lastNumAnimate"></span>
                                </>
                            ) : (
                                carCost || '000'
                            )}
                        </span>
                    </h3>
                </Cost>
            </SubCosts>
            {slug === 'nsw-health' && (
                <SubCosts>
                    <Cost>
                        <p>Share of savings</p>
                        <h3>
                            $
                            <span id="sos_cost">
                                {shareOfSavings || '000'}
                            </span>
                        </h3>
                    </Cost>
                    <Cost>
                        <p>Per fortnight </p>
                        <h3>
                            $
                            <span id="pf_cost">
                                {perFortnight || '000'}
                            </span>
                        </h3>
                    </Cost>
                </SubCosts>
            )}
        </MeterWrappper>
    );
};

const mapStateToProps = (state) => {
    return {
        isLoading: state.vehicle.isLoading,
        isCalculating: state.vehicle.isCalculating,
    };
};

export const Meter = connect(mapStateToProps)(MeterContainer);
